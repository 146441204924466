import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import { Draggable } from 'gsap/Draggable'
import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from 'tailwind.config.js'

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin, Draggable);

const tw = resolveConfig(tailwindConfig)

export default () => ({
  tween: null,
  years: [],
  current: 0,

  end () {
    gsap.to(window, {
      scrollTo: {
        y: this.tween.scrollTrigger.end + 100,
        autoKill: false
      },
      duration: 0.5
    });
  },

  init () {
    this.years = gsap.utils.toArray(this.$refs.timeline.children)
    let numMilestones = 0;

    this.years.forEach((year) => {
      numMilestones += year.querySelectorAll(':scope > ul > li').length
    })
    
    setTimeout(() => {

      this.tween = gsap.to(
        this.years,
        {
          x: () => {
            let movement = this.$refs.timeline.scrollWidth - window.innerWidth;
            if (window.matchMedia('(min-width: ' + tw.theme.screens.md + ')').matches) {
              movement = movement + this.$refs.timeline.lastElementChild.offsetWidth;
            }

            return -movement
          },
          ease: 'none',
          scrollTrigger: {
            trigger: this.$el,
            start: 'center center',
            end: () => {
              if (window.matchMedia('(min-width: ' + tw.theme.screens.md + ')').matches) {
                return '+=' + (this.$refs.timeline.scrollWidth - window.innerWidth + this.$refs.timeline.lastElementChild.offsetWidth)
              }

              return '+=' + (this.$refs.timeline.scrollWidth - window.innerWidth)
            },
            pin: this.$refs.pin,
            scrub: true,
            snap: {
              snapTo: (value) => {
                if (window.matchMedia('(min-width: ' + tw.theme.screens.md + ')').matches) {
                  return false
                }

                const increments = 1 / (numMilestones - 1)
                
                return Math.round(value / increments) * increments
              },
              inertia: false,
              duration: {min: 0.1, max: 0.5}
            },
          },
        }
      )
      
      if (! window.matchMedia('(min-width: ' + tw.theme.screens.md + ')').matches) {
        const draggable = Draggable.create(this.$refs.proxy, {
          trigger: this.$el,
          type: 'x,y',
          inertia: true,
          throwProps: true,
          allowEventDefault: true,
          autoScroll: 1,
          onDragEnd: function (width) {
            let movement = '';
            const el = this.pointerEvent.target;

            if (this.getDirection() == 'left') movement = '+='
            if (this.getDirection() == 'right') movement = '-='

            if (movement == '') {
              return;
            }

            gsap.to(window, {
              scrollTo: {
                y: movement +  width,
                autoKill: false
              },
              duration: 0.5
            });
          },
          onDragEndParams: [this.$refs.slide.offsetWidth]
        })
      }
    }, 500);
  },
  navigation: {
    ['@click.prevent']() {
      const target = document.querySelector(this.$el.getAttribute('href'))
      const totalScroll = this.tween.scrollTrigger.end - this.tween.scrollTrigger.start
      const totalMovement = (this.years.length - 1) * target.offsetWidth

      let y = Math.round(this.tween.scrollTrigger.start + (target.offsetLeft / totalMovement) * totalScroll)
      
      if (window.matchMedia('(min-width: ' + tw.theme.screens.md + ')').matches) {
        y  = Math.round(this.tween.scrollTrigger.start + target.offsetLeft)
      }

      gsap.to(window, {
        scrollTo: {
          y: y,
          autoKill: false
        },
        duration: 0.5
      });

      this.open = false;
    }
  }
})