const defaultSettings = {
  co2volume: 1000,
  co2refuelCount: 5,
  co2vehicleCount: 5,

  co2labourRate: 32, // per hour
  co2fuelingTime: 30, // minutes
}

export default (settings = {}) => {
  settings = {...defaultSettings, ...settings}

  return ({
    co2volume: parseInt(settings.co2volume),
    co2refuelCount: parseInt(settings.co2refuelCount),
    co2vehicleCount: parseInt(settings.co2vehicleCount),

    co2labourRate: parseInt(settings.co2labourRate),
    co2fuelingTime: parseInt(settings.co2fuelingTime),

    formatter: new Intl.NumberFormat('en-CA', {
      style: 'currency',
      currency: 'CAD',
      maximumFractionDigits: 0
    }),
    
    rounder: new Intl.NumberFormat('en-CA', {
      maximumFractionDigits: 0
    }),

    get co2annualVolume() {
      return this.co2volume * 12
    },

    get co2annualRefuelCount() {
      return this.co2refuelCount * 52
    },

    get co2fuelingTimeInHours() {
      return this.co2fuelingTime / 60
    },

    get co2avgLitersPerFill() {
      return Math.round(this.co2annualVolume / (this.co2vehicleCount * this.co2annualRefuelCount))
    },

    get co2annualFuelTimeSavings () {
      return Math.round(this.co2vehicleCount * this.co2annualRefuelCount * this.co2fuelingTimeInHours)
    },

    get co2annualLabourSavings () {
      return Math.round(this.co2annualFuelTimeSavings * this.co2labourRate)
    },

    get co2annualLabourSavingsPerVehicle () {
      return Math.round(this.co2annualLabourSavings / this.co2vehicleCount)
    },

    get usldCo2 () {
      return this.co2annualVolume * 3.59445 
    },

    get b20Co2 () {
      return this.co2annualVolume * 2.81564 
    },

    get r20Co2 () {
      return this.co2annualVolume * 3.07780 
    },

    get r30Co2 () {
      return this.co2annualVolume * 2.78051 
    },

    get r50Co2 () {
      return this.co2annualVolume * 2.17471 
    },

    get r100Co2 () {
      return this.co2annualVolume * 0.88132 
    }
  })
}