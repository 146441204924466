
/** @type {import('tailwindcss').Config} */

const plugin = require('tailwindcss/plugin')
const defaultTheme = require('tailwindcss/defaultTheme')

module.exports = {
  content: [
    "./index.php",
    "./app/**/*.php",
    "./resources/**/*.{php,vue,js}",
    "!./resources/blocks/*.js",
    "./config/acf.php"
  ],
  theme: {
    container: {
      center: true,
      padding: {
        DEFAULT: '2rem',
        md: '4rem',
        lg: '8rem',
        '2xl': '12rem'
      },
      screens: {
        sm: '1800px'
      }
    },
    colors: {
      purple: {
        DEFAULT: '#674D9F',
        light: '#5567B0'
      },
      blue: {
        DEFAULT: '#4380C1',
        dark: '#2659A3',
        light: '#28ABD0',
        teal: '#0091a0'
      },
      green: {
        DEFAULT: '#37B253',
        50: '#A4D52F',
        100: '#D1E332',
        300: '#79C263',
        500: '#505E0D',
        700: '#37B253',
        light: '#79C263',
        olive: '#505E0D',
        lime: '#D1E332'
      },
      yellow: {
        DEFAULT: '#FBED23'
      },
      gray: {
        100: '#F2F2F2',
        200: '#DEE1E3',
        300: '#C1C6CA',
        400: '#9DA3A9',
        500: '#62666A',
        600: '#3C3F42',
        700: '#2C2F32'
      },
      white: '#FFFFFF',
      black: '#000000',
      transparent: 'transparent',
      current: 'currentColor',
      accent: 'rgb(var(--accent) / <alpha-value>)',
      secondary: 'rgb(var(--secondary) / <alpha-value>)'
    },
    fontFamily: {
      'title': ['Poppins', 'sans-serif'],
      'body': ['CairoVariable', 'sans-serif']
    },
    fontSize: {
      '2xs': ['0.625rem', {
        lineHeight: 1.5
      }],
      xs: ['0.75rem', {
        lineHeight: 1.5
      }],
      sm: ['0.875rem', {
        lineHeight: 1.5
      }],
      base: ['1rem', {
        lineHeight: 1.5
      }],
      lg: ['1.125rem', {
        lineHeight: 1.5
      }],
      xl: ['1.25rem', {
        lineHeight: 1.5
      }],
      '2xl': ['1.5rem', {
        lineHeight: 1.5
      }],
      '3xl': ['1.875rem', {
        lineHeight: 1.5
      }],
      '4xl': ['2.5rem', {
        lineHeight: 1.5
      }],
      '5xl': ['3.25rem', {
        lineHeight: 0.9
      }],
      '6xl': ['4rem', {
        lineHeight: 0.9
      }],
      '7xl': ['5rem', {
        lineHeight: 0.9
      }],
    },
    maskImage: {
      brushed: 'url(@/assets/images/brushed-mask.png)',
      header: 'url(@/assets/images/page-header-mask.svg)',
      'footer-cta': 'url(@/assets/images/footer-cta.svg)',
      cta: 'url(@/assets/images/image-cta.svg)',
      'cta-2': 'url(@/assets/images/image-cta--mobile.svg)',
      overlay: 'url(@/assets/images/overlay-text.svg)',
      ribbon: 'url(@/assets/images/ribbon.svg)',
      icon: 'var(--icon)',
      'page-nav': 'url(@/assets/images/page-nav-mask.svg)',
      triangle: 'url("data:image/svg+xml,%3Csvg width=\'65\' height=\'169\' viewBox=\'0 0 65 169\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cpath d=\'M0 169L65 0H0V169Z\' fill=\'white\'/%3E%3C/svg%3E%0A")'
    },
    maskSize: ({ theme }) => ({
      full: '100%',
      auto: 'auto',
      ...theme('spacing')
    }),
    maskPosition: ({ theme }) => theme('backgroundPosition'),
    extend: {
      animation: {
        'floating-link-open': 'floating-link-open 300ms ease-in forwards',
        'floating-link-close': 'floating-link-close 300ms ease-in forwards'
      },
      backgroundImage: {
        'set-dir': 'linear-gradient(var(--tw-gradient-dir), var(--tw-gradient-stops))',
        'gradient-header': 'linear-gradient(90deg, rgba(251, 237, 35, 0.5) -4.03%, rgba(55, 178, 83, 0.5) 24.58%, rgba(67, 128, 193, 0.5) 55.42%, rgba(103, 77, 159, 0.25) 84.76%, rgba(103, 77, 159, 0) 100%)',
        'gradient-1': 'linear-gradient(90deg, #FBED23 0%, #C6DB3D 12%, #9CCD51 23%, #82C55E 31%, #79C263 36%, #62A789 48%, #4B8AB1 61%, #4380C1 68%, #5F59A6 93%, #684E9F 100%)',
        'gradient-2': 'linear-gradient(90deg, #FBED23 -4.03%, #37B253 24.58%, #4380C1 55.42%, rgba(103, 77, 159, 0.5) 84.76%, rgba(103, 77, 159, 0) 100%);',
        'gradient-3': 'linear-gradient(90deg, #75BD58 0%, #25A187 20.39%, #1E8EA9 39.94%, #3977B0 57.3%, #634D9C 80.72%, #7A4F9C 100%);',
        'gradient-4': 'linear-gradient(90deg, #D2DC44 0%, #5EB85E 18.96%, #25A187 34.86%, #1E8EA9 51.11%, #3977B0 68.12%, #535AA1 84.03%, #704B9A 100%);',
        'gradient-5': 'linear-gradient(90deg, #D2DC44 0%, #5EB85E 18.96%, #25A187 34.86%, #1E8EA9 51.11%, #3977B0 68.12%, #535AA1 84.03%, #704B9A 100%);',
        'gradient-6': 'linear-gradient(to right, #19939B 0%, #1D81A8 19.33%, #3B6CA8 44.39%, #5657A1 65.42%, #634D9C 79.49%, #7344A0 92.16%);',
        'gradient-7': 'linear-gradient(to top, #22A086 2.18%, #1A84A6 28.86%, #366FA9 45.19%, #5E509D 67.15%, #8E60A6 100%);',
        'footer-curve': 'url("data:image/svg+xml,%3Csvg width=\'360\' height=\'25\' viewBox=\'0 0 360 25\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cpath d=\'M308.786 24.9621C289.639 24.9621 270.757 24.4159 253.001 21.1391L122.881 1.9851C102.872 -1.72086 80.0813 -0.3165 65.7708 6.86137L43.1789 17.2381C33.506 22.1143 19.7915 24.9231 5.34846 24.9231H0L360 25L308.786 24.9621Z\' fill=\'black\'/%3E%3C/svg%3E")',
        'set-image': 'var(--set-image)',
        'header': 'url(@/assets/images/page-header-mask.svg)',
        'overlay': 'url(@/assets/images/overlay.svg)',
        'overlay-lines': 'url(@/assets/images/overlay-lines.svg)',
        'footer-cta': 'url(@/assets/images/footer-cta.svg)'
      },
      dropShadow: {
        '3xl': '0px 0px 45px rgba(0, 0, 0, 0.5)'
      },
      flexBasis: {
        even: 'calc((100% / var(--tw-flex-cols)) - (var(--tw-flex-gap) / var(--tw-flex-cols) * (var(--tw-flex-cols) - 1)))'
      },
      gap: {
        even: 'var(--tw-flex-gap)'
      },
      keyframes: {
        'floating-link-open': {
          '0%': {
            transform: 'rotateY(0deg)'
          },
          '100%': {
            transform: 'rotateY(180deg)'
          }
        },
        'floating-link-close': {
          '0%': {
            transform: 'rotateY(-180deg)'
          },
          '75%': {
            transform: 'rotateY(0deg)'
          },
          '100%': {
            transform: 'rotateY(0deg)'
          }
        },
        'bounce': {
          '0%,100%': {
            'transform': 'translateY(-10%)',
            'animation-timing-function': 'cubic-bezier(0.8, 0, 1, 1)'
          },
          '50%': {
            'transform': 'translateY(0)',
            'animation-timing-function': 'cubic-bezier(0, 0, 0.2, 1)'
          }
        }
      },
      typography: ({ theme }) => ({
        DEFAULT: {
          css: {
            'h1,h2,h3,h4, .alignleft h2': {
              fontSize: '2em',
              fontWeight: 600,
              lineHeight: 1
            },
            '.alignleft h2': {
              'marginTop': '1em !important',
              'marginBottom': '0.6em !important'
            },
            'blockquote': {
              borderLeft: 'none',
              fontStyle: 'none',
            },
            'cite': {
              fontStyle: 'italic',
              fontWeight: '400'
            },
            'a': {
              textDecoration: 'underline',
              textDecorationColor: 'transparent',
              transitionProperty: theme('transitionProperty.colors'),
              transitionDuration: theme('transitionDuration.150'),
              transitionTimingFunction: theme('transitionTimingFunction.ease-in-out'),
            },
            'a:hover': {
              textDecorationColor: 'currentColor'
            },
            maxWidth: 'none',
            '--tw-prose-body': theme('colors.black'),
            '--tw-prose-headings': theme('colors.black'),
            '--tw-prose-lead': theme('colors.black'),
            '--tw-prose-links': theme('colors.purple.DEFAULT'),
            '--tw-prose-bold': theme('colors.black'),
            '--tw-prose-counters': theme('colors.black'),
            '--tw-prose-bullets': theme('colors.black'),
            '--tw-prose-hr': theme('colors.black'),
            '--tw-prose-quotes': theme('colors.black'),
            '--tw-prose-quote-borders': theme('colors.black'),
            '--tw-prose-captions': theme('colors.black'),
            '--tw-prose-code': theme('colors.black'),
            '--tw-prose-pre-code': theme('colors.black'),
            '--tw-prose-pre-bg': theme('colors.black'),
            '--tw-prose-th-borders': theme('colors.black'),
            '--tw-prose-td-borders': theme('colors.black'),
            '--tw-prose-invert-body': theme('colors.white'),
            '--tw-prose-invert-headings': theme('colors.white'),
            '--tw-prose-invert-lead': theme('colors.white'),
            '--tw-prose-invert-links': theme('colors.white'),
            '--tw-prose-invert-bold': theme('colors.white'),
            '--tw-prose-invert-counters': theme('colors.white'),
            '--tw-prose-invert-bullets': theme('colors.white'),
            '--tw-prose-invert-hr': theme('colors.white'),
            '--tw-prose-invert-quotes': theme('colors.white'),
            '--tw-prose-invert-quote-borders': theme('colors.white'),
            '--tw-prose-invert-captions': theme('colors.white'),
            '--tw-prose-invert-code': theme('colors.white'),
            '--tw-prose-invert-pre-code': theme('colors.white'),
            '--tw-prose-invert-pre-bg': 'rgb(0 0 0 / 50%)',
            '--tw-prose-invert-th-borders': theme('colors.white'),
            '--tw-prose-invert-td-borders': theme('colors.white'),
          },
        }
      })
    },
  },
  plugins: [
    plugin(function({ addBase, addUtilities, matchUtilities, theme }) {
      addBase({
        ':root': {
          '--tw-mask-w': '100%',
          '--tw-mask-h': 'auto',
          '--tw-mask-position': 'center',
          '--tw-mask-clip': 'border-box'
        }
      })
      matchUtilities(
        {
          mask: (value) => ({
            '--tw-mask-position': value
          })
        },
        { values: theme('maskPosition') }
      )
      matchUtilities(
        {
          mask: (value) => ({
            '--tw-mask-size': 'var(--tw-mask-w) var(--tw-mask-h)',
            '--tw-mask-image': value,
            '--tw-mask': 'var(--tw-mask-image)',
            mask: 'var(--tw-mask) var(--tw-mask-position) / var(--tw-mask-size)',
            maskClip: 'var(--tw-mask-clip)',
            maskRepeat: 'no-repeat'
          })
        },
        { values: theme('maskImage') }
      )
      matchUtilities(
        {
          'mask-h': (value) => ({
            '--tw-mask-h': value,
          }),
          'mask-w': (value) => ({
            '--tw-mask-w': value,
          })
        },
        { values: theme('maskSize') }
      )
      matchUtilities(
        {
          'mask-x': (value) => ({
            '--tw-mask-x': value,
          }),
          'mask-y': (value) => ({
            '--tw-mask-y': value,
          })
        },
        { values: theme('maskPosition') }
      )
      matchUtilities(
        {
          'mask-clip': (value) => ({
            '--tw-mask-clip': value,
          }),
        },
        { values: theme('maskClip') }
      )
      addUtilities(
        {
          '.mask-contain': {
            '--tw-mask-size': 'contain'
          },
          '.mask-exclude': {
            '--tw-mask': 'var(--tw-mask-image) var(--tw-mask-position) / var(--tw-mask-size) no-repeat, linear-gradient(#fff 0%, #fff 0%)',
            maskComposite: 'exclude'
          }
        }
      )
    }),
    plugin(function({ addVariant }) {
      addVariant('inner', [
        '& > .block-editor-inner-blocks > .block-editor-block-list__layout',
        '& > .block-editor-block-list__layout'
      ]);
      addVariant('acf-inner', '& > .block-editor-block-list__layout')
      addVariant('inner-block', [
        '& > .block-editor-inner-blocks > .block-editor-block-list__layout > .block-editor-block-list__block',
        '& > .block-editor-block-list__layout > .block-editor-block-list__block'
      ]);
      addVariant('acf-inner-block', '& > .block-editor-block-list__layout > .block-editor-block-list__block');
      addVariant('appender', '& .block-list-appender');
    }),
    plugin(function({ matchVariant }) {
      matchVariant(
        'set',
        (value) => {
          let n = value * 2;
          let s = '';

          for (i = 1; i <= value; i++) {
            s += `:nth-child(${n}n+${value+i}),`;
          }

          return `&:is(${s.slice(0, -1)})`;
        },
        {
          values: {
            2: 2,
            3: 3,
            4: 4,
          }
        }
      );
    }),
    plugin(function({ addUtilities, matchUtilities, addBase, theme }) {
      addBase({
        ':root': {
          '--tw-clip-rounded-tl': '0px',
          '--tw-clip-rounded-tr': '0px',
          '--tw-clip-rounded-br': '0px',
          '--tw-clip-rounded-bl': '0px'
        }
      })

      addUtilities({
        '.clip-full': {
          'clip-path': 'inset(0 0 0 0 round var(--tw-clip-rounded-tl) var(--tw-clip-rounded-tr) var(--tw-clip-rounded-br) var(--tw-clip-rounded-bl))'
        }
      })

      matchUtilities(
        {
          'clip-rounded-tl': (value) => ({
            '--tw-clip-rounded-tl': value
          }),
          'clip-rounded-tr': (value) => ({
            '--tw-clip-rounded-tr': value
          }),
          'clip-rounded-bl': (value) => ({
            '--tw-clip-rounded-bl': value
          }),
          'clip-rounded-br': (value) => ({
            '--tw-clip-rounded-br': value
          }),
          'clip-rounded-t': (value) => ({
            '--tw-clip-rounded-tl': value,
            '--tw-clip-rounded-tr': value
          }),
          'clip-rounded-b': (value) => ({
            '--tw-clip-rounded-bl': value,
            '--tw-clip-rounded-br': value
          }),
          'clip-rounded': (value) => ({
            '--tw-clip-rounded-tl': value,
            '--tw-clip-rounded-tr': value,
            '--tw-clip-rounded-br': value,
            '--tw-clip-rounded-bl': value
          }),
        },
        { values: theme('borderRadius') }
      )
    }),
    plugin(function({ matchUtilities, addBase, theme }) {
      addBase({
        ':root': {
          '--tw-flex-cols': 1,
          '--tw-flex-gap': '0px',
        }
      })

      matchUtilities(
        {
          'flex-cols': (value) => ({
            '--tw-flex-cols': value
          })
        },
        {
          values: {
            1: '1',
            2: '2',
            3: '3',
            4: '4',
          }
        }
      )

      matchUtilities(
        {
          'flex-gap': (value) => ({
            '--tw-flex-gap': value
          })
        },
        {
          values: theme('spacing')
        }
      )
    }),
    plugin(function({ addVariant, matchVariant }) {
      addVariant('child-hover', '&:has(:merge(.child):hover)')
      addVariant('fr', '&:lang(fr)')
      addVariant('sp', '&:lang(es)')
      addVariant('us', 'body.is-US &')
      matchVariant(
        'group-nth',
        (value) => {
          return `:merge(.group):nth-child(${value.replaceAll('_', ' ')}) &`;
        },
        {
          values: {
            even: 'even',
            odd: 'odd'
          }
        }
      )
    }),
    require('@tailwindcss/typography')
  ],
  future: {
    hoverOnlyWhenSupported: true,
  }
};
