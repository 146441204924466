import './_bootstrap'
import './_fonts'

import header from './alpine/_header'
import App from './alpine/_App'
import simpleCarousel from './alpine/_simple-carousel'
import timeline from './alpine/_timeline'
import calculator from './alpine/_calculator'
import co2calculator from './alpine/_co2calculator'

import '../css/app.css'

window.Alpine.store('header', header)

window.Alpine.data('App', App)
window.Alpine.data('simpleCarousel', simpleCarousel);
window.Alpine.data('timeline', timeline);
window.Alpine.data('calculator', calculator);
window.Alpine.data('co2calculator', co2calculator);

window.Alpine.start()