const defaultSettings = {
  volume: 1000,
  refuelCount: 5,
  vehicleCount: 5,

  labourRate: 32, // per hour
  fuelingTime: 30, // minutes
}

export default (settings = {}) => {
  settings = {...defaultSettings, ...settings}

  return ({
    volume: parseInt(settings.volume),
    refuelCount: parseInt(settings.refuelCount),
    vehicleCount: parseInt(settings.vehicleCount),

    labourRate: parseInt(settings.labourRate),
    fuelingTime: parseInt(settings.fuelingTime),

    formatter: new Intl.NumberFormat('en-CA', {
      style: 'currency',
      currency: 'CAD',
      maximumFractionDigits: 0
    }),

    get annualVolume() {
      return this.volume * 12
    },

    get annualRefuelCount() {
      return this.refuelCount * 52
    },

    get fuelingTimeInHours() {
      return this.fuelingTime / 60
    },

    get avgLitersPerFill() {
      return Math.round(this.annualVolume / (this.vehicleCount * this.annualRefuelCount))
    },

    get annualFuelTimeSavings () {
      return Math.round(this.vehicleCount * this.annualRefuelCount * this.fuelingTimeInHours)
    },

    get annualLabourSavings () {
      return Math.round(this.annualFuelTimeSavings * this.labourRate)
    },

    get annualLabourSavingsPerVehicle () {
      return Math.round(this.annualLabourSavings / this.vehicleCount)
    }
  })
}